/* You can add global styles to this file, and also import other style files */
@import '../../shared/src/lib/shared_variables';
@import '@angular/cdk/overlay-prebuilt.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'jsoneditor/dist/jsoneditor.min.css';

@font-face {
  font-family: 'GrundfosTheSansV2';
  font-weight: 700;
  font-style: normal;
  src:
    url('./assets/fonts/GrundfosTheSansV2OT-7B.woff2') format('woff2'),
    url('./assets/fonts/GrundfosTheSansV2OT-7B.woff') format('woff');
}

@font-face {
  font-family: 'GrundfosTheSansV2';
  font-weight: 400;
  font-style: normal;
  src:
    url('./assets/fonts/GrundfosTheSansV2OT-4SL.woff2') format('woff2'),
    url('./assets/fonts/GrundfosTheSansV2OT-4SL.woff') format('woff');
}

html,
body {
  font-family: 'GrundfosTheSansV2' !important;
  background-color: #edf0f2;
}

.base-container {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.overview-header-row {
  width: 100%;
  height: 105px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  display: flex;
  justify-content: center;
}

.overview-header-row-inner {
  width: $default-width;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.overview-title {
  margin-top: 15px;

  width: auto;
  height: 36px;
  opacity: 0.8;
  font-family: 'GrundfosTheSansV2';
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #000000;

  @extend .non-selectable-text;
}

.overview-tabs-row {
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  display: flex;
  justify-content: center;
}

.overview-tabs-row-inner {
  width: $default-width;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.overview-tab {
  padding: 13px;

  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 6px solid white;
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #b6bec2;
  margin-right: 1em;
  cursor: pointer;

  &.selected {
    color: #313131;
    border-bottom: 6px solid #0068cb;
  }
}

.overview-header-row-right-container {
  display: flex;
  flex-direction: row;
}

.overview-header-row-right-container-filter {
  margin-right: 20px;
}

.step-container-title {
  opacity: 0.8;
  font-family: 'GrundfosTheSansV2';
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #000000;
}

.footer-container {
  margin-top: 40px;
  width: $default-width;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.foot-container-left {
  opacity: 0.8;
  font-family: 'GrundfosTheSansV2';
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #000000;
}

.foot-container-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

//Overview
.overview-table {
  width: $default-width;
  padding-top: 20px;
}

.overview-table-primary-element {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-color: #d9dddf;
  border-top-style: solid;
  border-top-width: 1px;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}

.overview-table-company-element-title {
  margin-left: 30px;
  width: auto;
  height: 21px;
  font-family: 'GrundfosTheSansV2';
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  color: #000000;

  @extend .non-selectable-text;
}

.overview-table-facility-element-tile {
  cursor: pointer;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top-color: #d9dddf;
  border-top-style: solid;
  border-top-width: 1px;

  background-color: #edf0f2;
  &.unevenindex {
    background-color: #ffffff;
  }
}

//
// TABLE STUFF
//

.cdk-overlay-dark-backdrop {
  background-color: rgba(13, 51, 84, 0.5);
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* styles from ts_shared */
.non-selectable-text {
  cursor: default;
  user-select: none;
}

.center-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

//Base Header Container
.app-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  position: fixed;
  z-index: 10;
}

//Header Top elements
.app-header-top {
  width: 100%;
  background-color: $main-gf-color;
  display: flex;
  justify-content: center;
  margin: auto;
}

.app-header-top-center {
  height: $app-header-top-height;
  width: $default-width;
  display: flex;
  color: white;
  align-items: flex-start;
}

.grundfos-logo {
  width: 180px;
  height: 26px;
  margin-top: 25px;
  margin-bottom: auto;
}

.vertical-divider {
  margin-top: 40px;
  margin-left: 19.5px;
  margin-right: 13px;
  width: 1px;
  height: 10px;
  border: solid 1px #7091af;
}

.app-header-top-title {
  margin-top: 37px;
  width: 205px;
  height: 17px;
  font-family: 'GrundfosTheSansV2OT-4SL';
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  color: #8cbbdd;
}

//Header Bottom Elements
.app-header-bottom {
  width: 100%;
  background-color: $dark-gf-color;
  display: flex;
  justify-content: center;
  margin: auto;
}

.app-header-bottom-center {
  width: $default-width;
  height: $app-header-bottom-height;
  display: flex;
  color: white;
  align-items: flex-start;
}

.app-header-bottom-title {
  margin-top: 16px;
  width: auto;
  height: 16px;
  font-family: 'GrundfosTheSansV2OT-7B';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: 0.16px;
  color: #ffffff;
}

/** Overides for ngPrime **/
.p-tree {
  border-radius: unset !important;
}

