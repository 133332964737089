$main-gf-color: #11497b;
$dark-gf-color: #0c3254;
$primary-background: #edf0f2;

$highlight: #4dbbeb;
$highlight-light: lighten($highlight, 5%);
$highlight-dark: darken($highlight, 5%);

// Status Colors
$warning: #f6aa33;
$warning-light: lighten($warning, 5%);
$warning-dark: darken($warning, 5%);

$error: #e33353;
$error-light: lighten($error, 5%);
$error-dark: darken($error, 5%);

$success: #6eb456;
$success-light: lighten($success, 5%);
$success-dark: darken($success, 5%);

//buttons
//buttonsPrimary
//normal
$buttons-color-primary-normal: #00a64f;
//hover
$buttons-color-primary-hover: #1aaf60;
//active
$buttons-color-primary-active: #009547;
//disabled
$buttons-color-primary-disabled: #c1ceda;
//buttonsSecondary
//normal
$buttons-color-secondary-normal: #0066b0;
//hover
$buttons-color-secondary-hover: #1a75b8;
//active
$buttons-color-secondary-active: #005c9e;
//disabled
$buttons-color-secondary-disabled: #c1ceda;
//input fields

$default-width: 1344px;
$app-header-top-height: 62px;
$app-header-bottom-height: 48px;
